import _buffer from "buffer";
import _pump from "pump";
import _bufferStream from "./buffer-stream";
var exports = {};
const {
  constants: BufferConstants
} = _buffer;
const pump = _pump;
const bufferStream = _bufferStream;

class MaxBufferError extends Error {
  constructor() {
    super("maxBuffer exceeded");
    this.name = "MaxBufferError";
  }

}

async function getStream(inputStream, options) {
  if (!inputStream) {
    return Promise.reject(new Error("Expected a stream"));
  }

  options = {
    maxBuffer: Infinity,
    ...options
  };
  const {
    maxBuffer
  } = options;
  let stream;
  await new Promise((resolve, reject) => {
    const rejectPromise = error => {
      // Don't retrieve an oversized buffer.
      if (error && stream.getBufferedLength() <= BufferConstants.MAX_LENGTH) {
        error.bufferedData = stream.getBufferedValue();
      }

      reject(error);
    };

    stream = pump(inputStream, bufferStream(options), error => {
      if (error) {
        rejectPromise(error);
        return;
      }

      resolve();
    });
    stream.on("data", () => {
      if (stream.getBufferedLength() > maxBuffer) {
        rejectPromise(new MaxBufferError());
      }
    });
  });
  return stream.getBufferedValue();
}

exports = getStream; // TODO: Remove this for the next major release

exports.default = getStream;

exports.buffer = (stream, options) => getStream(stream, { ...options,
  encoding: "buffer"
});

exports.array = (stream, options) => getStream(stream, { ...options,
  array: true
});

exports.MaxBufferError = MaxBufferError;
export default exports;
export const buffer = exports.buffer,
      array = exports.array;
const _MaxBufferError = exports.MaxBufferError;
export { _MaxBufferError as MaxBufferError };